import { LoadingScreen, EnvironmentBanner } from '@components';
import BrandGuard from './components/BrandGuard';
import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { EnvironmentType } from '@types';
import ReportPage from './components/Reporting';

const environment = process.env.REACT_APP_ENVIRONMENT as EnvironmentType;

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <EnvironmentBanner environment={environment} />
    <Component {...props} />
  </Suspense>
);

// Widget pages
const GroupToast = Loadable(lazy(() => import('./pages/GroupToast')));
const UserToast = Loadable(lazy(() => import('./pages/UserToast')));
const HoursOfOperation = Loadable(lazy(() => import('./pages/HoursOfOperation')));
// const Lounge = Loadable(lazy(() => import('./pages/Lounge')));
const Reservation = Loadable(lazy(() => import('./pages/Reservation')));
const GroupReservation = Loadable(lazy(() => import('./pages/GroupReservation')));
const Showroom = Loadable(lazy(() => import('./pages/Showroom')));
const GroupShowroom = Loadable(lazy(() => import('./pages/GroupShowroom')));
const ThankYou = Loadable(lazy(() => import('./pages/ThankYou')));
const StayUpdated = Loadable(lazy(() => import('./pages/StayUpdated')));
const GroupLounge = Loadable(lazy(() => import('./pages/GroupLounge')));
const Index = Loadable(lazy(() => import('./pages/Index')));
// const AddToCalendar = Loadable(lazy(() => import('./pages/AddToCalendar')));
const MissedTour = Loadable(lazy(() => import('./pages/MissedTour')));
const LoungeV2 = Loadable(lazy(() => import('./pages/LoungeV2')));
// const ModifyReservation = Loadable(lazy(() => import('./pages/ModifyReservation')));
// const ConfirmationReservation = Loadable(lazy(() => import('./pages/ConfirmationReservation')));
// const Tracker = Loadable(lazy(() => import('./pages/Tracking')));
// const PowershiftClaim = Loadable(lazy(() => import('./pages/PowershiftClaim')));
const DealerReferral = Loadable(lazy(() => import('./pages/DealerReferral')));
const ContactDealerPage = Loadable(lazy(() => import('./pages/ContactDealerPage')));

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));
// const Debug = Loadable(lazy(() => import('./pages/Debug')));

// Iframe test page
// const IframePage = Loadable(lazy(() => import('./pages/Iframe')));
// const LocalNotifications = Loadable(lazy(() => import('./pages/LocalNotifications')));

const routes = [
  {
    path: '',
    element: (
      <ReportPage>
        <Outlet />
      </ReportPage>
    ),
    children: [
      {
        path: '',
        element: <Index />,
      },
      {
        path: 'group-toast',
        element: (
          <BrandGuard pathName="group-toast">
            <GroupToast />
          </BrandGuard>
        ),
      },
      {
        path: 'user-toast',
        element: (
          <BrandGuard pathName="user-toast">
            <UserToast />
          </BrandGuard>
        ),
      },
      {
        path: 'thank-you',
        element: (
          <BrandGuard pathName="thank-you">
            <ThankYou />
          </BrandGuard>
        ),
      },
      {
        path: 'reservation',
        element: (
          <BrandGuard pathName="reservation">
            <Reservation />
          </BrandGuard>
        ),
      },
      {
        path: 'modify-reservation',
        element: (
          <BrandGuard pathName="modify-reservation">
            <Reservation />
          </BrandGuard>
        ),
      },
      {
        path: 'dealer-referral',
        element: (
          <BrandGuard pathName="dealer-referral">
            <DealerReferral />
          </BrandGuard>
        ),
      },
      {
        path: 'group-reservation',
        element: (
          <BrandGuard pathName="group-reservation">
            <GroupReservation />
          </BrandGuard>
        ),
      },
      {
        path: 'modify-group-reservation',
        element: (
          <BrandGuard pathName="modify-group-reservation">
            <GroupReservation />
          </BrandGuard>
        ),
      },
      // {
      //   path: 'reservation-confirm',
      //   element: (
      //     <BrandGuard>
      //       <ConfirmationReservation />
      //     </BrandGuard>
      //   ),
      // },
      // {
      //   path: 'modify-reservation-v2',
      //   element: (
      //     <BrandGuard>
      //       <ModifyReservation />
      //     </BrandGuard>
      //   ),
      // },
      {
        path: 'group-showroom',
        element: (
          <BrandGuard pathName="group-showroom">
            <GroupShowroom />
          </BrandGuard>
        ),
      },
      {
        path: 'showroom',
        element: (
          <BrandGuard pathName="showroom">
            <Showroom />
          </BrandGuard>
        ),
      },
      {
        path: 'stay-updated',
        element: (
          <BrandGuard pathName="stay-updated">
            <StayUpdated />
          </BrandGuard>
        ),
      },
      // {
      //   path: 'queue',
      //   element: (
      //     <BrandGuard>
      //       <Lounge />
      //     </BrandGuard>
      //   ),
      // },
      // {
      //   path: 'lounge',
      //   element: (
      //     <BrandGuard>
      //       <Lounge />
      //     </BrandGuard>
      //   ),
      // },
      {
        path: 'group-countdown',
        element: (
          <BrandGuard pathName="group-lounge">
            <GroupLounge />
          </BrandGuard>
        ),
      },
      {
        path: 'group-lounge',
        element: (
          <BrandGuard pathName="group-lounge">
            <GroupLounge />
          </BrandGuard>
        ),
      },
      {
        path: 'hours-of-operation',
        element: (
          <BrandGuard pathName="hours-of-operation">
            <HoursOfOperation />
          </BrandGuard>
        ),
      },
      // {
      //   path: 'add-to-calendar',
      //   element: (
      //     <BrandGuard>
      //       <AddToCalendar />
      //     </BrandGuard>
      //   ),
      // },
      // {
      //   path: 'iframe-page',
      //   element: (
      //     <BrandGuard>
      //       <IframePage />
      //     </BrandGuard>
      //   ),
      // },
      {
        path: 'missed-group-tour',
        element: (
          <BrandGuard pathName="missed-group-tour">
            <MissedTour />
          </BrandGuard>
        ),
      },
      {
        path: 'missed-group-tours',
        element: (
          <BrandGuard pathName="missed-group-tour">
            <MissedTour />
          </BrandGuard>
        ),
      },
      {
        path: 'group-tour-ended',
        element: (
          <BrandGuard pathName="missed-group-tour">
            <MissedTour />
          </BrandGuard>
        ),
      },
      // {
      //   path: 'local-notifications',
      //   element: (
      //     <BrandGuard>
      //       <LocalNotifications />
      //     </BrandGuard>
      //   ),
      // },
      {
        path: 'lounge-v2',
        element: (
          <BrandGuard pathName="lounge-v2">
            <LoungeV2 />
          </BrandGuard>
        ),
      },
      // {
      //   path: 'tracker',
      //   element: (
      //     <BrandGuard>
      //       <Tracker />
      //     </BrandGuard>
      //   ),
      // },
      // {
      //   path: 'debug',
      //   element: (
      //     <BrandGuard>
      //       <Debug />
      //     </BrandGuard>
      //   ),
      // },
      // {
      //   path: 'powershift-claim',
      //   element: (
      //     <BrandGuard>
      //       <PowershiftClaim />
      //     </BrandGuard>
      //   ),
      // },
      {
        path: 'contact-dealer',
        element: (
          <BrandGuard pathName="contact-dealer">
            <ContactDealerPage />
          </BrandGuard>
        ),
      },
    ],
  },

  {
    path: '*',
    children: [
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
