import {
  Box,
  Grid,
  InputLabel,
  OutlinedInput,
  Button,
  Typography,
  FormHelperText,
  FormControlLabel,
  Radio,
  RadioGroup,
  MenuProps,
  FormControl,
  MenuItem,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import { Vehicle, ContactDealer, Dealership, BrandType } from '@types';
import { ReactElement, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { TextFieldForm, GridWrapper, TextButton, ErrorComponent, SelectField, ModalIconButton, SelectBasicField } from '@components';
import { useTheme } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckIcon from '@mui/icons-material/Check';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import { useSortedDealerships, useReporting, useCurrentLanguageParam } from '@hooks';
import { ContactDeaderViewStyles } from './ContactDealerView.style';
import clsx from 'clsx';
import * as Scroll from 'react-scroll';
import { collection, orderBy, query, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { trimSpaces } from '@utils';

declare let window: any;

type ContactDealerViewProps = {
  vehicles?: Vehicle[];
  t3Dealership?: Dealership;
  brandId: BrandType;
  contactDealer?: ContactDealer;
  onClose?: () => void;
  onSave?: (contactDealer: ContactDealer) => void;
  onSubmit: (contactDealer: ContactDealer) => void;
  onCloseStatus?: (contactDealer: ContactDealer) => void;
  isModal?: boolean;
};

const EnergyMenuProps: Partial<MenuProps> = {
  PaperProps: {
    style: {
      maxHeight: 260,
      overflowY: 'auto',
      boxShadow: 'none',
      borderTop: 'none',
      borderLeft: '2px solid #000',
      borderRight: '2px solid #000',
      borderBottom: '2px solid #000',
    },
  },
  autoFocus: true,
  disableAutoFocusItem: true,
  disableEnforceFocus: true,
  disableAutoFocus: true,
};

const ContactDealerView = (props: ContactDealerViewProps): ReactElement => {
  const { t } = useTranslation();
  const { lang: languageCode } = useCurrentLanguageParam();
  const { contactDealer, t3Dealership, brandId, onClose, onSubmit, onSave, onCloseStatus, isModal } = props;
  const theme = useTheme();
  const [zipCode, setZipCode] = useState(contactDealer?.zipCode || '');
  const [postalCode, setPostalCode] = useState(contactDealer?.postalCode || '');
  const [brands] = useState(['Chevrolet', 'Cadillac', 'GMC', 'Buick']);
  const [selectedBrand, setSelectedBrand] = useState('All Brands');
  const { sortedDealerships } = useSortedDealerships(brandId, zipCode, postalCode, selectedBrand);
  const [validateErrors, setValidateErrors] = useState<boolean>(false);
  const [isVehicleOpen, setIsVehicleOpen] = useState<boolean>(false);

  const isCadillacUS = brandId === 'cadillac';
  const isCadillacCanada = brandId === 'cadillaccan';
  const isChevy = brandId === 'chevy';
  const isGMC = brandId === 'gmc';
  const isBuick = brandId.toLowerCase() === 'buick';
  const isEVLive = brandId.toLowerCase() === 'ev';
  const isFrench = languageCode === 'en-FR' || window.location.href.includes('en-FR') || window.location.href.includes('en-fr') || window.location.href.includes('fr');
  const isEVLiveCanada = brandId.toLowerCase() === 'evcan';
  const isEVLiveCanadaFrench = isEVLiveCanada && isFrench;
  const isEnergy = brandId.toLowerCase() === 'energy';

  let maxCommentLength = 150;
  if (isGMC || isBuick) maxCommentLength = 1000;
  if (isEVLive || isEVLiveCanada || isEVLiveCanadaFrench) maxCommentLength = 100;
  if (isEnergy) maxCommentLength = 32768;

  const useStyles = makeStyles(ContactDeaderViewStyles(brandId));
  const styles = useStyles();

  const { trackAdobe } = useReporting();
  const { Element } = Scroll;

  const trackErrorMessages = (errors: any) => {
    const errorMessages = [];
    let errorMessage = '';

    const firstName = 'Please provide a first name.';
    const lastName = 'Please provide a last name.';
    const email = 'Please provide a properly formatted email address.';
    const confirmEmail = 'Email addresses do not match';
    const city = 'Please provide a city.';
    const vehicleId = 'Please select a vehicle';
    const zipCodeE = 'Please provide a valid zip code.';

    if (errors.firstName) errorMessages.push(firstName);
    if (errors.lastName) errorMessages.push(lastName);
    if (errors.email) errorMessages.push(email);
    if (errors.confirmEmail) errorMessages.push(confirmEmail);
    if (errors.city) errorMessages.push(city);
    if (errors.vehicleId) errorMessages.push(vehicleId);
    if (errors.zipCode) errorMessages.push(zipCodeE);

    errorMessage = errorMessages.join(', ');
    if (errorMessage) {
      window.digitalData.formInfo.errorMessage = errorMessage;
    }
  };

  const executeScroll = (isValid: boolean) => {
    const modalEl = document.getElementById('errorContainer');
    if (isValid || !isValid) {
      setTimeout(() => {
        modalEl.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'start' });
      }, 1000);
      modalEl.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'start' });
    }
  };

  const vehicleRef = collection(useFirestore(), `brands/${brandId}/vehicles`);
  const vehicleQuery = query(
    vehicleRef,
    where('widgetIds', 'array-contains', 'Contact Dealer'),
    orderBy('index', 'asc')
  );
  const { data: vehicles } = useFirestoreCollectionData(vehicleQuery, {
    idField: 'id',
  });

  const handleOnClose = (initialValues: any, updatedValues: any) => {
    if ((initialValues === updatedValues && !contactDealer) || !isModal) onClose();
    else onCloseStatus(updatedValues);
  };

  return (
    <Formik
      initialValues={{
        firstName: contactDealer ? contactDealer.firstName : '',
        lastName: contactDealer ? contactDealer.lastName : '',
        email: contactDealer ? contactDealer.email : '',
        confirmEmail: contactDealer ? contactDealer.confirmEmail : '',
        city: contactDealer ? contactDealer.city : '',
        zipCode: contactDealer ? contactDealer.zipCode : '',
        postalCode: contactDealer ? contactDealer.postalCode : '',
        phoneNumber: contactDealer ? contactDealer.phoneNumber : '',
        dealershipId: contactDealer ? contactDealer.dealershipId : t3Dealership?.name || '',
        vehicleId: contactDealer ? contactDealer.vehicleId : '',
        comments: contactDealer ? contactDealer.comments : t("I'm interested in an electric car. Could you tell me the range on a full charge and the charging time with a home outlet versus a fast charger? Also, are there any incentives or rebates available? Lastly, what are the maintenance requirements?"),
        languageCode,
        brands: contactDealer ? contactDealer.brands : '',
        preferredContactMethod: contactDealer ? contactDealer.preferredContactMethod : '',
        receiveUpdates: contactDealer ? contactDealer.receiveUpdates : false,
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string()
          .transform((_, transformedValue) => trimSpaces(transformedValue))
          .max(40, t('First Name must be at most 40 characters.'))
          .matches(/^[aA-zZ\s]+$/, t('Contains invalid characters.'))
          .required(t('Please provide a first name.')),
        lastName: Yup.string()
          .transform((_, transformedValue) => trimSpaces(transformedValue))
          .max(40, t('Last Name must be at most 40 characters.'))
          .matches(/^[aA-zZ\s]+$/, t('Contains invalid characters.'))
          .required(t('Please provide a last name.')),
        email: Yup.string()
          .email(t('Please provide a properly formatted email address.'))
          .required(t('Please provide a properly formatted email address.')),
        confirmEmail: Yup.string()
          .oneOf([Yup.ref('email'), null], t('Email addresses do not match.'))
          .required(t('Email addresses do not match.')),
        city: Yup.string().required(t('Please provide a city.')),
        zipCode: !isCadillacCanada && !isEVLiveCanada
          ? Yup.string()
            .min(5, t('Zip Code is not valid.'))
            .max(5, t('Zip Code must be at most 5 characters.'))
            .matches(/^\d{5}(?:[-\s]\d{4})?$/, t('Zip Code is not valid.'))
            .required(t('Zip Code is not valid.'))
          : Yup.string(),
        postalCode: isCadillacCanada || isEVLiveCanada
          ? Yup.string()
            .min(6, t('Postal Code is not valid.'))
            .max(6, t('Postal Code must be at most 6 characters.'))
            .matches(
              /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
              t('Postal Code is not valid.'),
            )
            .required(t('Postal Code is not valid.'))
          : Yup.string(),
        phoneNumber: isEnergy ? Yup.string()
          .min(10, t('Please provide a valid phone number.'))
          .matches(
            /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            t('Phone number is limited to Canada and USA'),
          ) : Yup.string()
          .min(10, t('Please provide a valid phone number.'))
          .matches(
            /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            t('Phone number is limited to Canada and USA'),
          )
          .required(t('Please provide a valid phone number.')),
        preferredContactMethod: isEnergy
          ? null
          : Yup.string().required(t('Please select a preferred contact method.')),
        receiveUpdates: isEnergy
          ? Yup.boolean()
          : (isCadillacCanada || isEVLiveCanada) && Yup.string().required(t('Please make a selection.')),
        comments: Yup.string().max(maxCommentLength),
        dealershipId: isEnergy
          ? null
          : Yup.string().max(255).required(t('Please select a dealership.')).notOneOf(['']),
        vehicleId: isEnergy
          ? Yup.string().max(255).required(t('Please select a vehicle.')).notOneOf([''])
          : null,
        brands: (isEVLive || isEVLiveCanada)
          ? Yup.string().max(255).required(t('Please select a brand.')).notOneOf([''])
          : Yup.string(),
      })}
      onSubmit={async (values, { setStatus, setSubmitting, resetForm }): Promise<void> => {
        try {
          setStatus({ success: true });
          setSubmitting(false);
          resetForm();
          const selectedVehicle = vehicles.find((vehicle: Vehicle) => vehicle.id === values.vehicleId);
          const contactDealer = {
            ...values,
            brandId,
            date: new Date(),
            makeString: selectedVehicle.vehicleBrand ? selectedVehicle.vehicleBrand : brandId,
            model: selectedVehicle.name,
            modelYear: selectedVehicle.modelYear,
            dealershipName: t3Dealership?.name,
            country: 'US',
            bacId: t3Dealership?.id,
          } as ContactDealer;
          onSubmit(contactDealer);
          trackAdobe('test-drive');
          onClose();
        } catch (err) {
          console.error(err);
          toast.error(`Something went wrong! ${err.message}`);
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
        isValid,
        validateForm,
        submitForm,
        setFieldTouched,
        initialValues,
      }): JSX.Element => {
        const handleCheckForm = (event: React.ChangeEvent<HTMLFormElement>) => {
          event.preventDefault();
          if (isValid) {
            handleSubmit(event);
          }
        };
        if (window.digitalData) {
          trackErrorMessages(errors);
        }
        return (
          <>
            {(isEnergy && isModal) && (
              <ModalIconButton
                onClose={() => handleOnClose(initialValues, values)}
                brandId={brandId}
              />
            )}

            <Box className={`${isModal ? 'modal-container' : 'page-container'}`}>
              {(!isEnergy && isModal) && (
                <ModalIconButton
                  onClose={() => handleOnClose(initialValues, values)}
                  brandId={brandId}
                />
              )}
              {!isModal && (
                <Box className={styles.topLeftBackgroundImage} />
              )}
              <Box id="errorContainer">
                <Element
                  name="errorElement"
                  className={styles.errorElement}
                >
                  <ErrorComponent
                    containerId="errors"
                    condition={!isValid && validateErrors}
                    errors={errors}
                    dataDTM={isModal ? 'modal:connect with dealer' : 'connect with dealer'}
                  />
                </Element>
              </Box>
              <GridWrapper
                container
                className={`${isModal ? 'form-header' : 'form-header--page'}`}
              >
                <Typography
                  variant="modalTitle"
                  component="h2"
                >
                  {t('Connect with')} <span>{t3Dealership?.name}</span>
                </Typography>

                {!isEVLiveCanada && (
                  <Typography
                    variant="modalSubText"
                    component="p"
                    sx={{ mb: '15px', maxWidth: isEVLive || isEVLiveCanada ? '780px' : '100%' }}
                    className={brandId.toLowerCase() === 'chevy' ? styles.modalSubText : undefined}
                    dangerouslySetInnerHTML={{
                      __html: isModal ? t('Contact A Dealer: By providing my contact information below, I consent that Chevrolet, GM and/or a GM dealer can contact me with offers and product information. <a class="stat-text-link" data-dtm="modal:legal" href="https://www.gm.com/privacy-statement?evar25=gmenergy_energy-live_showroom" target="_blank" rel="noreferrer">GM Privacy Statement</a>')
                        : t('Contact A Dealer: By providing my contact information below, I consent that Chevrolet, GM and/or a GM dealer can contact me with offers and product information. <a href="https://www.gm.com/privacy-statement?evar25=gmenergy_connect-with-dealer" class="stat-text-link" data-dtm="connect with dealer" target="_blank">GM Privacy Statement</a>'),
                    }}
                  />
                )}

                {(isEVLive || isEVLiveCanada || isCadillacUS || isCadillacCanada) && (
                  <Typography
                    variant="modalSubText"
                    component="p"
                    className={styles.modalSubText}
                    dangerouslySetInnerHTML={{
                      __html: t('* Indicates Required Fields'),
                    }}
                  />
                )}
              </GridWrapper>

              <form
                id="test-drive"
                onSubmit={handleCheckForm}
                noValidate
                className={clsx(styles.form, 'test-drive')}
              >
                <Grid
                  container
                  spacing="30px"
                  className={styles.formContainer}
                >
                  {/* Left column */}
                  <Box className={clsx(styles.formColumn, styles.formLeftColumn)}>
                    {/* First name */}
                    <GridWrapper
                      item
                      md={6}
                      xs={12}
                      id="firstName"
                    >
                      {!isEnergy && (
                        <InputLabel>
                          <span dangerouslySetInnerHTML={{ __html: t('First Name (Required):') }} />
                        </InputLabel>
                      )}
                      <TextFieldForm
                        className="stat-input-field"
                        data-dtm={isModal ? 'modal:connect with dealer' : 'connect with dealer'}
                        error={Boolean(touched.firstName && errors.firstName)}
                        fullWidth
                        helperText={touched.firstName && errors.firstName}
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.firstName}
                        variant={isEnergy ? 'filled' : 'outlined'}
                        label={isEnergy ? t('First Name (Required):') : null}
                      />
                    </GridWrapper>

                    {/* Last name */}
                    <GridWrapper
                      item
                      md={6}
                      xs={12}
                      id="lastName"
                    >
                      {!isEnergy && (
                        <InputLabel>
                          <span dangerouslySetInnerHTML={{ __html: t('Last Name (Required):') }} />
                        </InputLabel>
                      )}
                      <TextFieldForm
                        className="stat-input-field"
                        data-dtm={isModal ? 'modal:connect with dealer' : 'connect with dealer'}
                        error={Boolean(touched.lastName && errors.lastName)}
                        fullWidth
                        helperText={touched.lastName && errors.lastName}
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.lastName}
                        variant={isEnergy ? 'filled' : 'outlined'}
                        label={isEnergy ? t('Last Name (Required):') : null}
                      />
                    </GridWrapper>

                    {/* Email */}
                    <GridWrapper
                      item
                      md={6}
                      xs={12}
                      id="email"
                    >
                      {!isEnergy && (
                        <InputLabel className={styles.inputLabel}>
                          <span dangerouslySetInnerHTML={{ __html: t('Email Address (Required):') }} />
                        </InputLabel>
                      )}
                      <TextFieldForm
                        className="stat-input-field"
                        data-dtm={isModal ? 'modal:connect with dealer' : 'connect with dealer'}
                        error={Boolean(touched.email && errors.email)}
                        type="email"
                        fullWidth
                        helperText={touched.email && errors.email}
                        name="email"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          let email = e.target.value;
                          if (brandId === 'cadillac' || brandId === 'cadillaccan') { email = email.toLowerCase(); }
                          setFieldValue('email', email);
                        }}
                        required
                        value={values.email}
                        variant={isEnergy ? 'filled' : 'outlined'}
                        label={isEnergy ? t('Email Address (Required):') : null}
                      />
                    </GridWrapper>

                    {/* Confirm email */}
                    <GridWrapper
                      item
                      md={6}
                      xs={12}
                      id="confirmEmail"
                    >
                      {!isEnergy && (
                        <InputLabel className={styles.inputLabel}>
                          <span dangerouslySetInnerHTML={{ __html: t('Confirm Email Address (Required):') }} />
                        </InputLabel>
                      )}
                      <TextFieldForm
                        className="stat-input-field"
                        data-dtm={isModal ? 'modal:connect with dealer' : 'connect with dealer'}
                        error={Boolean(touched.confirmEmail && errors.confirmEmail)}
                        type="email"
                        fullWidth
                        helperText={touched.confirmEmail && errors.confirmEmail}
                        name="confirmEmail"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          let email = e.target.value;
                          if (brandId === 'cadillac' || brandId === 'cadillaccan') { email = email.toLowerCase(); }
                          setFieldValue('confirmEmail', email);
                        }}
                        required
                        value={values.confirmEmail}
                        variant={isEnergy ? 'filled' : 'outlined'}
                        label={isEnergy ? t('Confirm Email Address (Required):') : null}
                      />
                    </GridWrapper>

                    {/* City */}
                    <GridWrapper
                      item
                      md={6}
                      xs={12}
                      id="city"
                    >
                      {!isEnergy && (
                        <InputLabel>
                          <span dangerouslySetInnerHTML={{ __html: t('City (Required):') }} />
                        </InputLabel>
                      )}
                      <TextFieldForm
                        className="stat-input-field"
                        data-dtm={isModal ? 'modal:connect with dealer' : 'connect with dealer'}
                        error={Boolean(touched.city && errors.city)}
                        fullWidth
                        helperText={touched.city && errors.city}
                        name="city"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.city}
                        variant={isEnergy ? 'filled' : 'outlined'}
                        label={isEnergy ? t('City (Required):') : null}
                      />
                    </GridWrapper>

                    {/* Postal Code / Zip Code */}
                    {isCadillacCanada || isEVLiveCanada ? (
                      <GridWrapper
                        item
                        md={6}
                        xs={12}
                        id="postalCode"
                      >
                        <InputLabel>
                          <span dangerouslySetInnerHTML={{ __html: t('Postal Code*') }} />
                        </InputLabel>
                        <TextFieldForm
                          className="stat-input-field"
                          data-dtm={isModal ? 'modal:connect with dealer' : 'connect with dealer'}
                          error={Boolean(touched.postalCode && errors.postalCode)}
                          fullWidth
                          helperText={touched.postalCode && errors.postalCode}
                          name="postalCode"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            setPostalCode(e.target.value);
                          }}
                          required
                          value={values.postalCode}
                          variant="outlined"
                        />
                      </GridWrapper>
                    ) : (
                      <GridWrapper
                        item
                        md={6}
                        xs={12}
                        id="zipCode"
                      >
                        {!isEnergy && (
                          <InputLabel>
                            <span dangerouslySetInnerHTML={{ __html: t('Zip Code (Required):') }} />
                          </InputLabel>
                        )}
                        <TextFieldForm
                          className="stat-input-field"
                          data-dtm={isModal ? 'modal:connect with dealer' : 'connect with dealer'}
                          error={Boolean(touched.zipCode && errors.zipCode)}
                          fullWidth
                          helperText={touched.zipCode && errors.zipCode}
                          name="zipCode"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            setZipCode(e.target.value);
                          }}
                          required
                          value={values.zipCode}
                          variant={isEnergy ? 'filled' : 'outlined'}
                          label={isEnergy ? t('Zip Code (Required):') : null}
                        />
                      </GridWrapper>
                    )}

                    {/* Phone number */}
                    <GridWrapper
                      item
                      md={6}
                      xs={12}
                      id="phoneNumber"
                    >
                      {!isEnergy && (
                        <InputLabel>
                          <span dangerouslySetInnerHTML={{ __html: t('Phone number (Required):') }} />
                        </InputLabel>
                      )}
                      <TextFieldForm
                        className="stat-input-field"
                        data-dtm={isModal ? 'modal:connect with dealer' : 'connect with dealer'}
                        error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                        type="phone"
                        fullWidth
                        helperText={touched.phoneNumber && errors.phoneNumber}
                        name="phoneNumber"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.phoneNumber}
                        variant={isEnergy ? 'filled' : 'outlined'}
                        label={isEnergy ? t('Phone number') : null}
                      />
                      {!(touched.phoneNumber && errors.phoneNumber) && (
                        <FormHelperText>{t('Optional')}</FormHelperText>
                      )}
                    </GridWrapper>

                    {/* Preferred Contact Method */}
                    {isEVLive || isEVLiveCanada && (
                      <GridWrapper
                        item
                        md={6}
                        xs={12}
                        id="PreferredContactMethod"
                      >
                        <InputLabel>
                          <span dangerouslySetInnerHTML={{ __html: t('Preferred Contact Method (Required):') }} />
                        </InputLabel>
                        <GridWrapper
                          container
                          className={styles.checkboxes}
                        >
                          <RadioGroup
                            id="preferredContactMethod"
                            name="preferredContactMethod"
                            value={values.preferredContactMethod}
                            onChange={handleChange}
                            sx={{
                              flexWrap: 'nowrap',
                              [theme.breakpoints.up('xs')]: {
                                flexDirection: 'row',
                              },
                            }}
                          >
                            <GridWrapper
                              item
                              md={6}
                              xs={12}
                              id="email"
                            >
                              <FormControlLabel
                                className="stat-checkbox"
                                data-dtm={isModal ? 'modal:connect with dealer' : 'connect with dealer'}
                                value="EM"
                                control={
                                  <Radio
                                    checkedIcon={<CheckIcon className="check-icon" />}
                                    icon={<CheckBoxOutlineBlankIcon className="checkbox-outlined-icon" />}
                                  />
                                }
                                label={`${t('Email')}`}
                              />
                            </GridWrapper>
                            <GridWrapper
                              item
                              md={6}
                              xs={12}
                              id="telephone"
                            >
                              <FormControlLabel
                                className="stat-checkbox"
                                data-dtm={isModal ? 'modal:connect with dealer' : 'connect with dealer'}
                                value="PH"
                                control={
                                  <Radio
                                    checkedIcon={<CheckIcon className="check-icon" />}
                                    icon={<CheckBoxOutlineBlankIcon className="checkbox-outlined-icon" />}
                                  />
                                }
                                label={`${t('Telephone')}`}
                              />
                            </GridWrapper>
                            <GridWrapper
                              item
                              md={6}
                              xs={12}
                              id="sms"
                            >
                              <FormControlLabel
                                className="stat-checkbox"
                                data-dtm={isModal ? 'modal:connect with dealer' : 'connect with dealer'}
                                value="SM"
                                control={
                                  <Radio
                                    checkedIcon={<CheckIcon className="check-icon" />}
                                    icon={<CheckBoxOutlineBlankIcon className="checkbox-outlined-icon" />}
                                  />
                                }
                                label={`${t('SMS')}`}
                              />
                            </GridWrapper>
                          </RadioGroup>
                          <Box sx={{ mt: -4 }}>
                            {touched.preferredContactMethod && errors.preferredContactMethod && (
                            <FormHelperText error>{t('Please select a preferred contact method.')}</FormHelperText>)}
                          </Box>
                        </GridWrapper>
                      </GridWrapper>
                    )}

                    {/* Select a brand  */}
                    {(isEVLive || isEVLiveCanada) && (
                      <GridWrapper
                        item
                        md={6}
                        xs={12}
                        id="vehicleIdModal"
                      >
                        <InputLabel className={styles.inputLabel}>
                          <span dangerouslySetInnerHTML={{ __html: t('Select a brand:') }} />
                        </InputLabel>
                        <GridWrapper>
                          <SelectField
                            className="stat-dropdown"
                            data-dtm={isModal ? 'modal:connect with dealer' : 'connect with dealer'}
                            variant="outlined"
                            fullWidth
                            error={Boolean(touched.brands && errors.brands)}
                            required
                            name="brands"
                            value={values.brands}
                            onChange={async (e) => {
                              const brand = e.target.value === 'Chevrolet' ? 'Chevy' : e.target.value; // GL-1278
                              // let filteredDealerships = dealerships;
                              // if (selectedBrand !== 'All Brands') {
                              //   filteredDealerships = filteredDealerships.filter((d) => d.brands && d.brands.findIndex((b) => b === selectedBrand) !== -1);
                              // }
                              // setSortedDealerships(filteredDealerships);

                              setFieldValue('dealershipId', t3Dealership?.name);
                              handleChange(e);
                              setSelectedBrand(brand);
                            }}
                            input={<OutlinedInput />}
                          >
                            <option
                              disabled
                              value=""
                            >
                              {t('All Brands')}
                            </option>
                            {brands && (
                              brands.map((brand) => (
                                <option
                                  className="stat-dropdown"
                                  data-dtm={isModal ? 'modal:connect with dealer' : 'connect with dealer'}
                                  key={brand}
                                  value={brand}
                                >
                                  {t(brand)}
                                </option>
                              )))}
                          </SelectField>
                          {isChevy && (
                            <div className="dropdown-icon">
                              <ArrowDropDownIcon sx={{ color: theme.palette.background.paper }} />
                            </div>
                          )}
                          {(isCadillacUS || isCadillacCanada || isGMC || isEVLive || isEVLiveCanada || isBuick) && (
                            <div
                              className="chevron-icon"
                              style={{
                                backgroundImage: `${theme.palette.background.chevronClosedStateIcon}`,
                              }}
                            />
                          )}
                        </GridWrapper>
                        {touched.brands && errors.brands && (
                          <FormHelperText error>{t('Please select a brand.')}</FormHelperText>
                        )}
                      </GridWrapper>
                    )}

                  </Box>

                  {isEnergy && (
                    <Box className={styles.divider} />
                  )}

                  {/* Right Column */}
                  <Box className={clsx(styles.formColumn, styles.formRightColumn)}>
                    {/* Dealership */}
                    {!isEnergy && (
                      <GridWrapper
                        item
                        md={6}
                        xs={12}
                        id="dealershipId"
                      >
                        {!isEnergy && (
                          <InputLabel className={styles.inputLabel}>
                            <span dangerouslySetInnerHTML={{ __html: t('Select a Dealership (Required):') }} />
                          </InputLabel>
                        )}
                        <GridWrapper>
                          <SelectField
                            className="stat-dropdown"
                            data-dtm={isModal ? 'modal:connect with dealer' : 'connect with dealer'}
                            fullWidth
                            error={Boolean(touched.dealershipId && errors.dealershipId)}
                            required
                            name="dealershipId"
                            value={values.dealershipId}
                            onChange={handleChange}
                            input={<OutlinedInput />}
                            variant={isEnergy ? 'filled' : 'outlined'}
                            // label={isEnergy ? t('Select a Dealership (Required):') : null}
                          >
                            <option
                              disabled
                              value=""
                            >
                              {t('All Dealerships')}
                            </option>
                            {sortedDealerships
                              && sortedDealerships.map((dealership) => (
                                <option
                                  key={dealership.id}
                                  value={dealership.id}
                                >
                                  {dealership.name}
                                </option>
                              ))}
                          </SelectField>
                          {isChevy && (
                            <div className="dropdown-icon">
                              <ArrowDropDownIcon sx={{ color: theme.palette.background.paper }} />
                            </div>
                          )}
                          {(isCadillacUS || isCadillacCanada || isGMC || isEVLive || isEVLiveCanada || isBuick) && (
                            <div
                              className="chevron-icon"
                              style={{
                                backgroundImage: `${theme.palette.background.chevronClosedStateIcon}`,
                              }}
                            />
                          )}
                        </GridWrapper>
                        {touched.dealershipId && errors.dealershipId && (
                          <FormHelperText error>{t('Please select a dealership.')}</FormHelperText>
                        )}
                      </GridWrapper>
                    )}
                    {/* {isEnergy && (
                      <GridWrapper
                        item
                        md={6}
                        xs={12}
                        id="dealershipId"
                        className="readyOnlyField"
                      >
                        <TextFieldForm
                          className="stat-input-field"
                          data-dtm={isModal ? 'modal:connect with dealer' : 'connect with dealer'}
                          error={Boolean(touched.dealershipId && errors.dealershipId)}
                          fullWidth
                          helperText={touched.dealershipId && errors.dealershipId}
                          name="dealershipId"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          defaultValue={t3Dealership.name}
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="filled"
                          label={t('Dealership')}
                          multiline
                        />
                      </GridWrapper>
                    )} */}

                    {/* Select Vehicle */}
                    {isEnergy && (
                      <GridWrapper
                        item
                        md
                        xs={12}
                        id="vehicleId"
                      >
                        <GridWrapper className={clsx(styles.selectWrapper, 'select-wrapper')}>
                          <FormControl
                            fullWidth
                            variant="filled"
                          >
                            <InputLabel>{t<string>('Select Vehicle')}</InputLabel>
                            <SelectBasicField
                              className="stat-dropdown"
                              data-dtm={isModal ? 'modal:connect with dealer' : 'connect with dealer'}
                              fullWidth
                              name="vehicleId"
                              value={values.vehicleId}
                              error={Boolean(touched.vehicleId && errors.vehicleId && !isVehicleOpen)}
                              onBlur={handleBlur}
                              onFocus={() => setFieldTouched('vehicleId', true)}
                              onChange={handleChange}
                              required
                              MenuProps={EnergyMenuProps}
                              open={isVehicleOpen}
                              onOpen={() => setIsVehicleOpen(true)}
                              onClose={() => setIsVehicleOpen(false)}
                              variant="outlined"
                              input={<OutlinedInput />}
                            >
                              {vehicles && vehicles.map((vehicle: Vehicle) => (
                                <MenuItem
                                  key={vehicle.index}
                                  value={vehicle.id}
                                  sx={{
                                    py: '16px',
                                    pr: 0,
                                    fontWeight: values.vehicleId === vehicle.id ? 'bold' : 'normal',
                                    whiteSpace: 'normal',
                                  }}
                                >
                                  {values.vehicleId === vehicle.id && (<CheckIcon
                                    sx={{
                                      backgroundColor: theme.palette.color.checkboxGrey,
                                      color: theme.palette.grey[800],
                                      mr: '0.5rem',
                                      width: '1rem',
                                      height: '1rem',
                                    }}
                                  />)}
                                  {values.vehicleId !== vehicle.id && (<CheckBoxOutlineBlankIcon
                                    sx={{
                                      backgroundColor: theme.palette.color.checkboxGrey,
                                      '& path': { display: 'none' },
                                      mr: '0.5rem',
                                      width: '1rem',
                                      height: '1rem',
                                    }}
                                  />)}
                                  {t(vehicle.name)}
                                </MenuItem>
                              ))}
                            </SelectBasicField>
                          </FormControl>
                          <div
                            className="chevron-icon"
                            style={{
                              backgroundImage: theme.palette.background.chevronClosedStateIcon,
                              transform: isVehicleOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                            }}
                          />
                        </GridWrapper>
                        {touched.vehicleId && errors.vehicleId && !isVehicleOpen && (
                          <FormHelperText error>{errors.vehicleId}</FormHelperText>
                        )}
                      </GridWrapper>
                    )}

                    {/* Comments */}
                    <GridWrapper
                      item
                      xs={12}
                      id="comments"
                    >
                      {!isEnergy && (
                        <InputLabel>
                          <span dangerouslySetInnerHTML={{ __html: t('Comments (Optional):') }} />
                        </InputLabel>
                      )}
                      <TextFieldForm
                        className={clsx(styles.textField, 'stat-input-field')}
                        data-dtm={isModal ? 'modal:connect with dealer' : 'connect with dealer'}
                        error={Boolean(touched.comments && errors.comments)}
                        helperText={touched.comments && errors.comments}
                        name="comments"
                        multiline
                        minRows={6}
                        inputProps={{ maxLength: maxCommentLength }}
                        value={values.comments}
                        // value={comments}
                        // defaultValue={values.comments}
                        // onChange={(event) => {
                        //   const { value } = event.target;
                        //   setComments(value);
                        // }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant={isEnergy ? 'filled' : 'outlined'}
                        label={isEnergy ? t('Comments (Optional):') : null}
                      />
                      {/* Character count */}
                      {(isEVLive || isEVLiveCanada) && (
                        <Typography
                          component="h5"
                          variant="characterCount"
                          className={styles.characterCount}
                        >
                          {/* {comments.length}/{maxCommentLength} */}
                          {values.comments.length}/{maxCommentLength}
                        </Typography>
                      )}
                      {isEnergy && (
                        <FormHelperText>{t('Optional (1,000 character max.)')}</FormHelperText>
                      )}
                    </GridWrapper>
                  </Box>
                </Grid>

                {/* Checkbox */}
                {(isEVLiveCanada) && (
                  <Grid
                    container
                    className={styles.checkboxes}
                  >
                    <RadioGroup
                      id="receiveUpdates"
                      name="receiveUpdates"
                      value={values.receiveUpdates}
                      onChange={handleChange}
                      sx={{
                        flexWrap: 'nowrap',
                        [theme.breakpoints.up('md')]: {
                          flexDirection: 'row',
                        },
                      }}
                    >
                      <GridWrapper
                        item
                        md={6}
                        xs={12}
                        id="optIn"
                      >
                        <FormControlLabel
                          className="stat-checkbox"
                          data-dtm={isModal ? 'modal:connect with dealer' : 'connect with dealer'}
                          value="Y"
                          control={
                            <Radio
                              checkedIcon={<CheckIcon className="check-icon" />}
                              icon={<CheckBoxOutlineBlankIcon className="checkbox-outlined-icon" />}
                            />
                          }
                          label={t<string>('Opt In to Electronic Marketing Communications ')}
                        />
                        <Typography
                          variant="modalSubText"
                          component="p"
                          dangerouslySetInnerHTML={{
                            __html: t('YES! I want to receive email and other electronic communications with news, offers and updates about products and services from General Motors of Canada company that are relevant to my interests based on information you have about me. I understand that I may withdraw my consent at any time*')
                          }}
                        />
                      </GridWrapper>
                      <GridWrapper
                        item
                        md={6}
                        xs={12}
                        id="optOut"
                      >
                        <FormControlLabel
                          className="stat-checkbox"
                          data-dtm={isModal ? 'modal:connect with dealer' : 'connect with dealer'}
                          value="N"
                          control={
                            <Radio
                              checkedIcon={<CheckIcon className="check-icon" />}
                              icon={<CheckBoxOutlineBlankIcon className="checkbox-outlined-icon" />}
                            />
                        }
                          label={`${t('Opt Out')}`}
                        />
                        <Typography
                          variant="modalSubText"
                          component="p"
                          dangerouslySetInnerHTML={{
                            __html:
                              t('General Motors of Canada Company would like to periodically provide you with information on new products and services that are relevant to you based on information we have about you and use your information in accordance with our privacy policy. If you wish the information provided be used solely to administer your request, simply check the box.')
                          }}
                        />
                      </GridWrapper>
                    </RadioGroup>
                  </Grid>
                )}

                {!isEnergy && (
                  <Grid>
                    <Typography
                      variant="modalSubText"
                      component="p"
                      className={styles.submitConfirmation}
                      dangerouslySetInnerHTML={{
                        __html: t<string>(
                          'By clicking submit I confirm that I would like the dealer named above to contact me by email and/or phone to respond to my request.',
                        ),
                      }}
                    />
                  </Grid>
                )}

                {/* {isEnergy && (
                  <GridWrapper
                    item
                    xs={12}
                    textAlign="left"
                    id="receiveUpdates"
                    className="consent-checkbox"
                  >
                    <FormControlLabel
                      sx={{ alignItems: 'flex-start', height: 'unset !important', }}
                      className={values.receiveUpdates ? 'is-checkbox-checked' : ''}
                      control={
                        <Checkbox
                          sx={{ mt: -1 }}
                          className="stat-checkbox"
                          data-dtm={isModal ? 'modal:connect with dealer' : 'connect with dealer'}
                        />
                      }
                      label={`${t("I'd like to be contacted by GM Energy about Bi-Directional Charging. Please contact me regarding GM Energy Products.")}`}
                      name="receiveUpdates"
                      checked={values.receiveUpdates === true}
                      onChange={handleChange}
                    />
                  </GridWrapper>
                )} */}

                <GridWrapper
                  container
                  className={clsx(`${styles.buttonContainer}`, `${!isModal ? 'form-button-container' : ''}`)}
                >
                  <Button
                    className={clsx('stat-button-link', styles.submitButton)}
                    data-dtm={isModal ? 'modal:connect with dealer' : 'connect with dealer'}
                    onClick={() => {
                      validateForm()
                        .then(() => trackErrorMessages(errors))
                        .then(() => submitForm())
                        .catch((e) => new Error(e));
                      setValidateErrors(true);
                      executeScroll(isValid);
                    }}
                    disabled={isSubmitting}
                    variant="primary"
                    type="submit"
                    aria-label="Submit book test drive"
                  >
                    {t('Submit')}
                  </Button>
                  {onSave && (
                    <TextButton
                      className={`${styles.saveButton} ${isGMC || isBuick || isEnergy ? 'stat-button-link' : 'stat-text-link'
                      }`}
                      data-dtm={isModal ? 'modal:connect with dealer' : 'connect with dealer'}
                      disableRipple
                      variant={isGMC || isBuick ? 'tertiary' : 'text'}
                      type="button"
                      // @ts-ignore
                      onClick={() => handleOnClose(initialValues, values)}
                      aria-label="Save book test drive"
                    >
                      {isEnergy ? t('Cancel') : t('No thanks')}
                    </TextButton>
                  )}
                </GridWrapper>

                {isEVLiveCanada && (
                  <Grid sx={{ mt: '50px' }}>
                    <Typography
                      variant="modalSubText"
                      component="p"
                      className={styles.legalFooter}
                      dangerouslySetInnerHTML={{
                        __html:
                        t('* General Motors of Canada Company (GM) is located at  500 Wentworth Street West, Oshawa, ON, L1J 0C5, 1-800-GM DRIVE, gm.ca, contact us, privacy policy.You may withdraw your consent for receiving marketing emails from GM, any time by contacting us. If you contact us to unsubscribe you will no longer receive marketing messages from us, but we may still send you other electronic messages such as those relating to your transactions with us, health and safety matters or those that we are required or permitted by law to send to you. If you do not click “YES”, your marketing status with us will not change but you can contact us anytime to change your marketing preferences.',),
                      }}
                    />
                  </Grid>
                )}
              </form>
            </Box>
          </>
        );
      }}
    </Formik>
  );
};
export default ContactDealerView;
