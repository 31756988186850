import { useTheme } from '@material-ui/core/styles';

export const ContactDeaderViewStyles = (brandId: string) => {
  const theme = useTheme();

  const chevyStyles = {
    form: {
      marginTop: '70px',
    },
    modalSubText: {
      maxWidth: '540px',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      [theme.breakpoints.up(768)]: {
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
      },
    },
    phoneNumberInput: {
      width: '50%',
      paddingRight: '15px',
      [theme.breakpoints.down(768)]: {
        width: '100%',
        paddingRight: 0,
      },
      '@global': {
        '.MuiOutlinedInput-input': {
          padding: '0 0 10px 0',
        },
      },
    },
    textArea: {
      height: '100%',
    },
    inputLabel: {
      [theme.breakpoints.between(768, 900)]: {
        whiteSpace: 'break-spaces',
      },
      [theme.breakpoints.down(480)]: {
        whiteSpace: 'break-spaces',
      },
    },
    errorElement: {},
    legalFooter: {},
    '@global': {
      '#optIn': {
        marginBottom: '36px',
        [theme.breakpoints.up('md')]: {
          paddingRight: '13px',
          marginBottom: '0px',
        },
      },
      '#optOut': {
        marginBottom: 0,
        [theme.breakpoints.up('md')]: {
          paddingLeft: '13px',
        },
      },
      '.privacy-statement-link': {
        fontWeight: 700,
        textDecoration: 'underline',
        color: theme.palette.color.privacyStatement,
      },
      '.MuiTypography-modalSubText a': {
        fontWeight: 700,
        textDecoration: 'underline',
        color: theme.palette.color.privacyStatement,
      },
      '.MuiNativeSelect-icon, .MuiSelect-icon': {
        display: 'none',
      },
    },
    characterCount: {
      marginBottom: '36px',
    },
    submitButton: {},
    saveButton: {},
  };

  const cadillacStyles = {
    buttonContainer: {
      [theme.breakpoints.up(768)]: {
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    characterCount: {
      marginBottom: '75px',
      [theme.breakpoints.up(768)]: {
        marginBottom: '92px',
      },
    },
    checkIcon: {
      position: 'absolute',
      top: '6px',
      right: '5px',
    },
    saveButton: {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    '@global': {
      '#vehicleIdModal, #dealershipId': {
        '& .MuiOutlinedInput-input': {
          padding: '0',
          width: 'calc(100% - 10px)',
        },
        '& .MuiInputBase-root': {
          padding: '7px 0px 7px 0px',
        },
        '& .MuiSelect-select': {
          paddingLeft: '10px !important',
        },
      },
    },
  };

  const cadillaccanStyles = {
    ...cadillacStyles,
    // checkIcon: {
    //   position: 'absolute',
    //   top: '6px',
    //   right: '5px',
    // },
    characterCount: {
      marginBottom: '36px',
      [theme.breakpoints.up(768)]: {
        marginBottom: '36px',
      },
    },
  };

  const gmcStyles = {
    characterCount: {
      marginBottom: '30px',
      [theme.breakpoints.up(768)]: {
        marginBottom: '55px',
      },
    },
    buttonContainer: {
      [theme.breakpoints.up(768)]: {
        justifyContent: 'space-between',
        flexDirection: 'row',
      },
    },
    submitButton: {
      width: 240,
      [theme.breakpoints.up('md')]: {
        width: '100%',
        maxWidth: 350,
      },
    },
    saveButton: {
      width: 240,
      marginTop: '25px',
      [theme.breakpoints.up('md')]: {
        marginTop: 0,
        marginLeft: '45px',
        width: '100%',
        maxWidth: 350,
      },
    },
    phoneNumberInput: {
      paddingRight: '22.5px',
    },
    textField: {
      '@global': {
        '.MuiOutlinedInput-root': {
          height: '125px !important',
          padding: '20px',
          [theme.breakpoints.up('md')]: {
            height: '175px !important',
          },
        },
        '.MuiOutlinedInput-input': {
          height: '90px !important',
          padding: '0px !important',
          textTransform: 'initial !important',
          [theme.breakpoints.up('md')]: {
            height: '140px !important',
          },
        },
      },
    },
    '@global': {
      '#vehicleIdModal, #dealershipId': {
        '& .MuiOutlinedInput-input': {
          padding: '0',
          width: 'calc(100% - 10px)',
        },
        '& .MuiInputBase-root': {
          padding: '7px 0px 7px 0px',
        },
        '& .MuiSelect-select': {
          paddingLeft: '10px !important',
        },
      },
      '.MuiSelect-icon': {
        display: 'none',
      },
      '.MuiContainer-root': {
        padding: '0px !important',
      },
      '.MuiGrid-container': {
        width: 'calc(100% + 25px)',
        marginLeft: '-25px',
        [theme.breakpoints.up(768)]: {
          width: 'calc(100% + 45px)',
          marginLeft: '-45px',
        },
      },
      '.MuiGrid-container.error-wrapping-container, .error-container': {
        width: '100%',
        marginLeft: '0',
        [theme.breakpoints.up(768)]: {
          width: '100%',
          marginLeft: '0',
        },
      },
      '.MuiGrid-item': {
        paddingTop: '26px !important',
        paddingLeft: '25px !important',
        [theme.breakpoints.up(768)]: {
          paddingTop: '30px !important',
          paddingLeft: '45px !important',
        },
      },
      '.MuiTypography-modalSubText a': {
        display: 'table',
        fontWeight: 'inherit !important',
      },
    },
  };

  const buickStyles = {
    modalSubText: {
      maxWidth: '100%',
    },
    characterCount: {
      marginBottom: '45px',
      [theme.breakpoints.up(768)]: {
        marginBottom: '70px',
      },
    },
    buttonContainer: {
      [theme.breakpoints.up(768)]: {
        justifyContent: 'space-between',
        flexDirection: 'row',
      },
    },
    submitButton: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 'calc(50% + 25px)',
        maxWidth: 'calc(50% + 25px)',
      },
      [theme.breakpoints.up('lg')]: {
        width: 380,
        maxWidth: 380,
      },
    },
    saveButton: {
      width: '100%',
      marginTop: '25px',
      [theme.breakpoints.up('md')]: {
        marginTop: 0,
        marginLeft: '50px',
        width: 'calc(50% + 25px)',
        maxWidth: 'calc(50% + 25px)',
      },
      [theme.breakpoints.up('lg')]: {
        width: 380,
        maxWidth: 380,
      },
    },
    phoneNumberInput: {
      paddingRight: 0,
      [theme.breakpoints.up('md')]: {
        paddingRight: 0,
      },
    },
    textField: {
      '@global': {
        '.MuiOutlinedInput-root': {
          height: '125px !important',
          padding: '20px',
          [theme.breakpoints.up('md')]: {
            height: '175px !important',
          },
        },
        '.MuiOutlinedInput-input': {
          height: '90px !important',
          padding: '0px !important',
          textTransform: 'initial !important',
          [theme.breakpoints.up('md')]: {
            height: '140px !important',
          },
        },
      },
    },
    '@global': {
      '#phoneNumber': {
        [theme.breakpoints.up('md')]: {
          paddingRight: '50px',
        },
      },
      '#vehicleIdModal, #dealershipId': {
        '& .MuiOutlinedInput-input': {
          padding: '0',
          width: 'calc(100% - 10px)',
        },
        '& .MuiInputBase-root': {
          padding: '7px 0px 7px 0px',
        },
        '& .MuiSelect-select': {
          paddingLeft: '10px !important',
        },
      },
      '.MuiSelect-icon': {
        display: 'none',
      },
      '.MuiGrid-container': {
        width: 'calc(100% + 20px)',
        marginLeft: '-20px',
        [theme.breakpoints.up(768)]: {
          width: 'calc(100% + 50px)',
          marginLeft: '-50px',
        },
      },
      '.MuiGrid-item': {
        paddingTop: '20px !important',
        paddingLeft: '20px !important',
        [theme.breakpoints.up(768)]: {
          paddingTop: '30px !important',
          paddingLeft: '50px !important',
        },
      },
      '.MuiTypography-modalSubText': {
        textAlign: 'center',
        [theme.breakpoints.up(768)]: {
          textAlign: 'left',
        },
      },
      '.MuiTypography-modalSubText a': {
        width: '100%',
        display: 'table',
        fontWeight: 'inherit !important',
        [theme.breakpoints.up(768)]: {
          textAlign: 'left',
          width: 'unset',
        },
      },
    },
  };

  const evliveStyles = {
    buttonContainer: {
      [theme.breakpoints.up(768)]: {
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
      },
    },
    submitButton: {
      width: '100%',
      marginBottom: 12,
    },
    saveButton: {
      width: '100%',
      textDecoration: 'underline',
      [theme.breakpoints.up(768)]: {
        width: 'auto',
        maxWidth: 'none',
      },
    },
    submitConfirmation: {
      paddingLeft: '50px',
      paddingRight: '50px',
      marginTop: '36px',
      textAlign: 'center',
      [theme.breakpoints.up(768)]: {
        padding: 0,
        marginLeft: '50%',
        textAlign: 'right',
      },
    },
    legalFooter: {
      '@global': {
        a: {
          display: 'unset !important',
        },
      },
    },
    '@global': {
      '.MuiInputBase-root': {
        '&:hover': {
          background: 'transparent !important',
        },
        '&.Mui-focused': {
          background: 'transparent !important',
        },
      },
      '.MuiTypography-modalSubText a': {
        '@media screen and (min-width: 768px)': {
          display: 'table',
        },
      },
      '#receiveUpdates .MuiTypography-modalSubText a': {
        display: 'unset',
        '@media screen and (min-width: 768px)': {
          display: 'unset',
        },
      },
      '.check-icon': {
        fontSize: '1.3rem',
        color: theme.palette.grey[300],
        backgroundColor: theme.palette.grey[200],
      },
      '.checkbox-outlined-icon': {
        '& path': { display: 'none' },
        fontSize: '1.3rem',
        backgroundColor: theme.palette.grey[200],
      },
      '.MuiFormGroup-root': {
        borderBottom: 'none!important',
      },
    },
  };

  const energyStyles = {
    topLeftBackgroundImage: {
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='155' height='106' viewBox='0 0 155 106' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='111.566' y1='72.8663' x2='-173.736' y2='72.8663' stroke='%23ED95FD' stroke-width='0.851648'/%3E%3Cline x1='155' y1='25.1729' x2='-174.588' y2='25.1729' stroke='%23ED95FD' stroke-width='0.851648'/%3E%3Cline x1='88.1465' y1='0.901367' x2='88.1465' y2='96.286' stroke='%23ED95FD' stroke-width='0.851648'/%3E%3Cline x1='40.4532' y1='0.901367' x2='40.4531' y2='143.978' stroke='%23ED95FD' stroke-width='0.851648'/%3E%3Cline x1='135.838' y1='0.901367' x2='135.838' y2='48.5937' stroke='%23ED95FD' stroke-width='0.851648'/%3E%3C/svg%3E%0A")`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: 205,
      [theme.breakpoints.up(1200)]: {
        height: 151,
      },
    },
    form: {
      width: '100%',
      maxWidth: 800,
      marginTop: 16,
      [theme.breakpoints.up(1200)]: {
        marginTop: 32,
      },
    },
    formContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.up(1200)]: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        flexWrap: 'nowrap',
      },
    },
    formColumn: {
      width: '100%',
      [theme.breakpoints.up(1200)]: {
        maxWidth: 336,
      },
    },
    formLeftColumn: {
      [theme.breakpoints.up(1200)]: {
        marginRight: 32,
      },
    },
    formRightColumn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      [theme.breakpoints.up(1200)]: {
        marginLeft: 32,
      },
    },
    divider: {
      display: 'block',
      width: '100%',
      height: 1,
      marginBottom: 16,
      border: '1px solid #B3B3B3',
      [theme.breakpoints.up(768)]: {
        marginTop: 16,
      },
      [theme.breakpoints.up(1200)]: {
        width: 1,
        height: 614,
        marginTop: 0,
        marginBottom: 0,
      },
    },
    selectWrapper: {
      position: 'relative',
      [theme.breakpoints.up(1200)]: {
        width: '324.55px !important',
        maxWidth: '324.55px !important',
      },
    },
    buttonContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '32px !important',
      [theme.breakpoints.up(1200)]: {
        marginTop: '48px !important',
      },
    },
    textField: {
      '@global': {
        '.MuiFilledInput-input': {
          height: '155px !important',
          [theme.breakpoints.up(1200)]: {
            height: '207px !important',
          },
        },
      },
    },
    saveButton: {
      paddingTop: '6px',
      paddingBottom: '6px',
      marginTop: '16px',
    },
    '@global': {
      '#errors': {
        marginTop: 24,
        '& .error-container': {
          marginBottom: 16,
          [theme.breakpoints.up(768)]: {
            marginBottom: 16,
          },
          [theme.breakpoints.up(1200)]: {
            marginBottom: 32,
          },
        },
      },
      '.MuiGrid-container': {
        width: '100%',
        marginTop: 0,
        marginLeft: 0,
      },
      '.MuiGrid-item': {
        width: '100%',
        padding: '0 !important',
        '&#phoneNumber, &#lastName, &#confirmEmail ': {
          paddingLeft: '0px !important',
        },
        [theme.breakpoints.up(768)]: {
          maxWidth: '100%',
          marginBottom: '16px !important',
        },
        [theme.breakpoints.up(1200)]: {
          maxWidth: '100%',
          marginBottom: '35px !important',
        },
      },
      '#vehicleId': {
        '& .MuiSvgIcon-root': {
          display: 'none',
        },
        '& .MuiGrid-root': {
          maxWidth: '100%'
        },
      },
      '.MuiGrid-root': {
        '& .MuiFormHelperText-root.Mui-error': {
          [theme.breakpoints.up(768)]: {
            position: 'relative !important',
            left: 0,
            bottom: 0,
          },
          // This is temporary fix, so that when error shows up underneth the field, it doesn't push down or increase the form field height
          [theme.breakpoints.up(1200)]: {
            position: 'absolute !important',
            left: 0,
            bottom: '-18px',
          },
        },
      },
      '.consent-checkbox': {
        [theme.breakpoints.up(768)]: {
          marginTop: 16,
          marginBottom: 16,
        },
        [theme.breakpoints.up(1200)]: {
          marginTop: 32,
          marginBottom: 32,
        },
      },
      '#phoneNumber': {
        '&.MuiGrid-item': {
          [theme.breakpoints.up(1200)]: {
            marginBottom: '0px !important',
          },
        },
      },
      // Page
      '.grid-wrapper': {
        paddingTop: '237px !important',
        paddingBottom: '32px !important',
        maxWidth: '805px !important',
        '& #errors': {
          marginTop: 0,
        },
        '& .consent-checkbox': {},
        '@media screen and (min-width: 1200px)': {
          paddingTop: '187px !important',
          paddingLeft: '0px !important',
          paddingRight: '0px !important',
          '& .consent-checkbox': {
            marginTop: 48,
            marginBottom: '48px !important',
          },
        },
      },
      '.form-header--page': {
        '@media screen and (min-width: 768px)': {
          '& .MuiTypography-modalTitle': {
            fontSize: '52px',
            lineHeight: '48px',
          },
          '& .MuiTypography-modalSubText': {
            maxWidth: '100% !important',
            fontSize: '16px',
            lineHeight: '20px',
          },
        },
        '@media screen and (min-width: 1200px)': {
          '& .MuiTypography-modalTitle': {
            fontSize: '60px',
            lineHeight: '56px',
          },
          '& .MuiTypography-modalSubText': {
            fontSize: '23px',
            lineHeight: '28px',
          },
        },
      },
      '.page-container': {
        '@media screen and (min-width: 1200px)': {
          '& .select-wrapper': {
            width: '100% !important',
            maxWidth: '100% !important',
          },
        },
      },
      '.form-button-container': {
        [theme.breakpoints.up(1200)]: {
          marginTop: '48px !important',
        },
        '& .MuiButton-root': {
          '@media screen and (min-width: 768px)': {
            maxWidth: '370px !important',
          },
          '@media screen and (min-width: 1200px)': {
            maxWidth: '315px !important',
          },
        },
      },
      // End Page
    },
  };

  const styles = {
    chevy: {
      ...chevyStyles,
    },
    cadillac: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...cadillacStyles,
      },
    },
    cadillaccan: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...cadillaccanStyles,
      },
    },
    gmc: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...gmcStyles,
      },
    },
    buick: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...buickStyles,
      },
    },
    ev: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...evliveStyles,
      },
    },
    evcan: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...evliveStyles,
      },
    },
    energy: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...energyStyles,
      },
    },
  };

  return styles[brandId];
};
